import { LabradorArticleTeaserBodytext } from 'base/components/labrador/ArticleTeaser/Bodytext';
import { LabradorComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';

export const SvenskdamLabradorArticleTeaserBodytext: LabradorComponent = (props) => {
  return (
    <LabradorArticleTeaserBodytext
      {...mergeProps(props, {
        data: {
          category: '',
          kicker: props.data.kicker || 'LÄS MER PÅ SVENSK DAM',
        },
      })}
    />
  );
};
