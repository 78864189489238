import { omitBy } from 'lodash-es';

type WithoutSubOptions<T> = {
  [P in keyof T as P extends `$${string}` ? never : P]: T[P];
};

type WithOptions = <T extends Record<string, any>>(options?: T) => WithoutSubOptions<T> | undefined;

const isSubOptions = (value: any, key: string) => key.startsWith('$');

export const withOptions: WithOptions = (options) => {
  if (!options) return;

  return omitBy(options, isSubOptions) as WithoutSubOptions<typeof options>;
};
