import { LabradorComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { LabradorArticleTeaserDefault } from 'base/components/labrador/ArticleTeaser/Default';

export const SvenskdamLabradorArticleTeaserDefault: LabradorComponent = (props) => {
  return (
    <LabradorArticleTeaserDefault
      {...mergeProps(
        {
          data: {
            displayProminentTagOnTeaser: !!props.data.prominentTag,
          },
        },
        props,
      )}
    />
  );
};
