import { tw } from '@/utils/tw';
import { default as BaseShowHero } from 'base/components/ShowHero/theme';

const { Tag } = BaseShowHero;

const ShowHero = tw.theme({
  extend: BaseShowHero,
  slots: {
    base: '-mx-3 md:mx-0',
    category: '-mb-6',
    headline: 'text-headline-lg md:text-headline-3xl',
    description: 'text-body-sm md:text-body-md',
  },
});

export default Object.assign(ShowHero, { Tag });
