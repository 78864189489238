import { tw } from '@/utils/tw';

import DialogTheme from 'base/components/standalone/Dialog/theme';

const { CloseIcon, DialogIcon } = DialogTheme;

const Dialog = tw.theme({
  extend: DialogTheme,
  slots: {
    base: ['backdrop:bg-[rgba(0,0,0,0.6)]'],
    button: ['bg-primary-700'],
    content: ['text-body-sm'],
    headline: ['text-headline-lg'],
  },
});

export default Object.assign(Dialog, { CloseIcon, DialogIcon });
