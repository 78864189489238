import { useMobileAppState } from '@/hooks/useMobileAppState';
import { LabradorComponent } from '@/types/component';
import { LabradorTrendingMenu } from 'base/components/labrador/TrendingMenu';

export const SvenskdamLabradorTrendingMenu: LabradorComponent = ({ type, data, meta, descendants }) => {
  const { isMobileApp } = useMobileAppState();

  if (isMobileApp) {
    return null;
  }

  return <LabradorTrendingMenu {...{ type, data, meta, descendants }} />;
};
