import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';
import BaseTagsIndexTheme from 'base/components/TagsIndex/theme';

const Tag = tw.theme({
  extend: TagTheme,
  base: ``,
  variants: {
    variant: {
      text: ``,
    },
    size: {
      text: `text-body-sm`,
    },
    colors: {
      text: `text-black hover:underline`,
    },
  },
  defaultVariants: {
    variant: 'text',
    size: 'text',
    colors: 'text',
  },
});

const TagsIndex = tw.theme({
  extend: BaseTagsIndexTheme,
  slots: {
    base: ``,
    headline: `mb-6 border-b-gray-300 text-headline-lg md:text-headline-2xl`,
    group: ``,
    letter: `text-primary-700 mt-0`,
    tags: `mb-5`,
  },
});

export default Object.assign(TagsIndex, { Tag });
