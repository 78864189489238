import { tw } from '@/utils/tw';
import BaseBylineTheme from 'base/components/Byline/theme';

const { Image } = BaseBylineTheme;

const Byline = tw.theme({
  extend: BaseBylineTheme,
  slots: {
    text: `inline-flex h-9 w-9 items-center justify-center text-headline-2xs`,
    author: `text-headline-2xs`,
  },
});

export default Object.assign(Byline, { Image });
