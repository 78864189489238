import { useClassName } from '@/styles/ExpertSlider';
import { merge } from '@/utils/merge';
import { GenericComponentFactory } from 'base/components/GenericComponent';
import NextImage from 'next/image';
import { register } from 'swiper/element/bundle';

register();

const $ = GenericComponentFactory({ useClassName });

const Base = $({ as: 'section' });
const Headline = $({ as: 'h3', styles: { key: 'headline' } });
const Slider = $({ as: 'swiper-container', styles: { key: 'slider' } });
const Slide = $({ as: 'swiper-slide', styles: { key: 'slide' } });
const SlideImage = $({ as: NextImage, styles: { key: 'slide_image' } });
const SlideCaption = $({ as: 'span', styles: { key: 'slide_caption' } });
const SlideDescription = $({ as: 'p', styles: { key: 'slide_description' } });

export const ExpertSlider = merge(Base, {
  Headline,
  Slider,
  Slide: merge(Slide, {
    Image: SlideImage,
    Caption: SlideCaption,
    Description: SlideDescription,
  }),
});
