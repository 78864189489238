import { tw } from '@/utils/tw';
import BaseArticleTeaserBodytextTheme from 'base/components/ArticleTeaser/Bodytext/theme';

const { Image } = BaseArticleTeaserBodytextTheme;

const ArticleTeaserBodytext = tw.theme({
  extend: BaseArticleTeaserBodytextTheme,
  slots: {
    content: ``,
    description: `text-headline-2xs md:text-headline-xs`,
    headline: `text-headline-3xs`,
  },
  variants: {
    variant: {
      first: {
        content: `rounded-none bg-gray-100 text-black`,
      },
    },
  },
});

export default Object.assign(ArticleTeaserBodytext, { Image });
