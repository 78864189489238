/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { SvenskdamStandaloneExpertSlider as ResolvedStandaloneExpertSlider } from 'sites/svenskdam/components/standalone/Expert/Slider';

export const ExpertSlider: typeof ResolvedStandaloneExpertSlider = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneExpertSlider {...props} />
        </ErrorBoundary>
    );
});

export type ExpertSliderProps = PropsFromComponent<typeof ExpertSlider>;

/** @deprecated Use slot architecture instead */
export const ExpertSliderWith = (extras: DynamicStandaloneExtras): typeof ExpertSlider => {
    return function ExpertSlider(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'ExpertSlider');
        return <ResolvedStandaloneExpertSlider {...mergeProps({ options: { theme } }, props)} />;
    }
}