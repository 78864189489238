/* THIS FILE IS GENERATED. DO NOT EDIT */

import { Accordion, AccordionProps } from './Accordion';
import { AdTemplate, AdTemplateProps } from './AdTemplate';
import { Adunit, AdunitProps } from './Adunit';
import { AffiliateGallery, AffiliateGalleryProps } from './AffiliateGallery';
import { AffiliateItem, AffiliateItemProps } from './AffiliateItem';
import { Article, ArticleProps } from './Article';
import { ArticleDate, ArticleDateProps } from './ArticleDate';
import { ArticleDisclosureLabel, ArticleDisclosureLabelProps } from './ArticleDisclosureLabel';
import { ArticleFooter, ArticleFooterProps } from './ArticleFooter';
import { ArticleHeader, ArticleHeaderProps } from './ArticleHeader';
import { ArticleSlider, ArticleSliderProps } from './ArticleSlider';
import { ArticleTeaserBodytext, ArticleTeaserBodytextProps } from './ArticleTeaser/Bodytext';
import { ArticleTeaserDefault, ArticleTeaserDefaultProps } from './ArticleTeaser/Default';
import { AuthorDetails, AuthorDetailsProps } from './AuthorDetails';
import { AuthorsList, AuthorsListProps } from './AuthorsList';
import { Banner, BannerProps } from './Banner';
import { Base, BaseProps } from './Base';
import { Blip, BlipProps } from './Blip';
import { BlogSlider, BlogSliderProps } from './BlogSlider';
import { Box, BoxProps } from './Box';
import { Breadcrumbs, BreadcrumbsProps } from './Breadcrumbs';
import { Button, ButtonProps } from './Button';
import { Byline, BylineProps } from './Byline';
import { Checkbox, CheckboxProps } from './Checkbox';
import { Checkout, CheckoutProps } from './Checkout';
import { CheckoutBody, CheckoutBodyProps } from './CheckoutBody';
import { CheckoutFooter, CheckoutFooterProps } from './CheckoutFooter';
import { CheckoutHeader, CheckoutHeaderProps } from './CheckoutHeader';
import { Columns, ColumnsProps } from './Columns';
import { Confirmation, ConfirmationProps } from './Confirmation';
import { Consent, ConsentProps } from './Consent';
import { ContactEditorial, ContactEditorialProps } from './ContactEditorial';
import { ContentLock, ContentLockProps } from './ContentLock';
import { CourseCountdown, CourseCountdownProps } from './CourseCountdown';
import { CoverBox, CoverBoxProps } from './CoverBox';
import { Dialog, DialogProps } from './Dialog';
import { Direkt, DirektProps } from './Direkt';
import { Edge, EdgeProps } from './Edge';
import { Emphasis, EmphasisProps } from './Emphasis';
import { Facebook, FacebookProps } from './Facebook';
import { FactBox, FactBoxProps } from './FactBox';
import { Footer, FooterProps } from './Footer';
import { Front, FrontProps } from './Front';
import { FrontCoverstory, FrontCoverstoryProps } from './FrontCoverstory';
import { Hamburger, HamburgerProps } from './Hamburger';
import { Heading, HeadingProps } from './Heading';
import { HighlightedArticles, HighlightedArticlesProps } from './HighlightedArticles';
import { Icon, IconProps } from './Icon';
import { Image, ImageProps } from './Image';
import { InputCaption, InputCaptionProps } from './InputCaption';
import { Instagram, InstagramProps } from './Instagram';
import { InstagramArticlesList, InstagramArticlesListProps } from './InstagramArticlesList';
import { JwVideoArticle, JwVideoArticleProps } from './JwVideo/Article';
import { JwVideoShoppable, JwVideoShoppableProps } from './JwVideo/Shoppable';
import { JwVideoTeaser, JwVideoTeaserProps } from './JwVideo/Teaser';
import { JwVideoVideo, JwVideoVideoProps } from './JwVideo/Video';
import { KeesingGames, KeesingGamesProps } from './KeesingGames';
import { KlarnaPayment, KlarnaPaymentProps } from './KlarnaPayment';
import { LineBreak, LineBreakProps } from './LineBreak';
import { Link, LinkProps } from './Link';
import { List, ListProps } from './List';
import { ListGrid, ListGridProps } from './ListGrid';
import { Loader, LoaderProps } from './Loader';
import { LoopAround, LoopAroundProps } from './LoopAround';
import { Main, MainProps } from './Main';
import { Markup, MarkupProps } from './Markup';
import { MoreEpisodes, MoreEpisodesProps } from './MoreEpisodes';
import { MorePagesTitle, MorePagesTitleProps } from './MorePagesTitle';
import { MostRead, MostReadProps } from './MostRead';
import { Navigation, NavigationProps } from './Navigation';
import { NewsletterSignup, NewsletterSignupProps } from './NewsletterSignup';
import { OptionalLink, OptionalLinkProps } from './OptionalLink';
import { Page4XX, Page4XXProps } from './Page4XX';
import { Page500, Page500Props } from './Page500';
import { PageMeta, PageMetaProps } from './PageMeta';
import { Pagination, PaginationProps } from './Pagination';
import { Paragraph, ParagraphProps } from './Paragraph';
import { Pre, PreProps } from './Pre';
import { Prose, ProseProps } from './Prose';
import { Quotebox, QuoteboxProps } from './Quotebox';
import { RadioButton, RadioButtonProps } from './RadioButton';
import { Recaptcha, RecaptchaProps } from './Recaptcha';
import { RecommendedArticles, RecommendedArticlesProps } from './RecommendedArticles';
import { RelatedArticleSlider, RelatedArticleSliderProps } from './RelatedArticleSlider';
import { RichTextBox, RichTextBoxProps } from './RichTextBox';
import { Row, RowProps } from './Row';
import { SaveArticleButton, SaveArticleButtonProps } from './SaveArticleButton';
import { SavedArticles, SavedArticlesProps } from './SavedArticles';
import { SchemaMarkup, SchemaMarkupProps } from './SchemaMarkup';
import { ScrollPageControls, ScrollPageControlsProps } from './ScrollPageControls';
import { Search, SearchProps } from './Search';
import { SecondNativeArticle, SecondNativeArticleProps } from './SecondNativeArticle';
import { ShowHero, ShowHeroProps } from './ShowHero';
import { ShowsSlider, ShowsSliderProps } from './ShowsSlider';
import { Skeleton, SkeletonProps } from './Skeleton';
import { SliderSlide, SliderSlideProps } from './Slider/Slide';
import { SliderSwiper, SliderSwiperProps } from './Slider/Swiper';
import { Slider, SliderProps } from './Slider';
import { SoMe, SoMeProps } from './SoMe';
import { Spinner, SpinnerProps } from './Spinner';
import { StateProviders, StateProvidersProps } from './StateProviders';
import { Strossle, StrossleProps } from './Strossle';
import { Tabs, TabsProps } from './Tabs';
import { Tag, TagProps } from './Tag';
import { TagsIndex, TagsIndexProps } from './TagsIndex';
import { TextArea, TextAreaProps } from './TextArea';
import { TextField, TextFieldProps } from './TextField';
import { TextMultiline, TextMultilineProps } from './TextMultiline';
import { TextSubtitle, TextSubtitleProps } from './TextSubtitle';
import { TextTitle, TextTitleProps } from './TextTitle';
import { Tiktok, TiktokProps } from './Tiktok';
import { Timer, TimerProps } from './Timer';
import { Tracking, TrackingProps } from './Tracking';
import { TrendingMenu, TrendingMenuProps } from './TrendingMenu';
import { Twitter, TwitterProps } from './Twitter';
import { VideoBaseContent, VideoBaseContentProps } from './VideoBaseContent';
import { VideoReels, VideoReelsProps } from './VideoReels';
import { VideoSlider, VideoSliderProps } from './VideoSlider';
import { VideoTranscript, VideoTranscriptProps } from './VideoTranscript';
import { Youplay, YouplayProps } from './Youplay';
import { Youtube, YoutubeProps } from './Youtube';
import { ExpertSlider, ExpertSliderProps } from './Expert/Slider';

export type ComponentsProps = {
  Accordion: AccordionProps;
  AdTemplate: AdTemplateProps;
  Adunit: AdunitProps;
  AffiliateGallery: AffiliateGalleryProps;
  AffiliateItem: AffiliateItemProps;
  Article: ArticleProps;
  ArticleDate: ArticleDateProps;
  ArticleDisclosureLabel: ArticleDisclosureLabelProps;
  ArticleFooter: ArticleFooterProps;
  ArticleHeader: ArticleHeaderProps;
  ArticleSlider: ArticleSliderProps;
  ArticleTeaserBodytext: ArticleTeaserBodytextProps;
  ArticleTeaserDefault: ArticleTeaserDefaultProps;
  AuthorDetails: AuthorDetailsProps;
  AuthorsList: AuthorsListProps;
  Banner: BannerProps;
  Base: BaseProps;
  Blip: BlipProps;
  BlogSlider: BlogSliderProps;
  Box: BoxProps;
  Breadcrumbs: BreadcrumbsProps;
  Button: ButtonProps;
  Byline: BylineProps;
  Checkbox: CheckboxProps;
  Checkout: CheckoutProps;
  CheckoutBody: CheckoutBodyProps;
  CheckoutFooter: CheckoutFooterProps;
  CheckoutHeader: CheckoutHeaderProps;
  Columns: ColumnsProps;
  Confirmation: ConfirmationProps;
  Consent: ConsentProps;
  ContactEditorial: ContactEditorialProps;
  ContentLock: ContentLockProps;
  CourseCountdown: CourseCountdownProps;
  CoverBox: CoverBoxProps;
  Dialog: DialogProps;
  Direkt: DirektProps;
  Edge: EdgeProps;
  Emphasis: EmphasisProps;
  Facebook: FacebookProps;
  FactBox: FactBoxProps;
  Footer: FooterProps;
  Front: FrontProps;
  FrontCoverstory: FrontCoverstoryProps;
  Hamburger: HamburgerProps;
  Heading: HeadingProps;
  HighlightedArticles: HighlightedArticlesProps;
  Icon: IconProps;
  Image: ImageProps;
  InputCaption: InputCaptionProps;
  Instagram: InstagramProps;
  InstagramArticlesList: InstagramArticlesListProps;
  JwVideoArticle: JwVideoArticleProps;
  JwVideoShoppable: JwVideoShoppableProps;
  JwVideoTeaser: JwVideoTeaserProps;
  JwVideoVideo: JwVideoVideoProps;
  KeesingGames: KeesingGamesProps;
  KlarnaPayment: KlarnaPaymentProps;
  LineBreak: LineBreakProps;
  Link: LinkProps;
  List: ListProps;
  ListGrid: ListGridProps;
  Loader: LoaderProps;
  LoopAround: LoopAroundProps;
  Main: MainProps;
  Markup: MarkupProps;
  MoreEpisodes: MoreEpisodesProps;
  MorePagesTitle: MorePagesTitleProps;
  MostRead: MostReadProps;
  Navigation: NavigationProps;
  NewsletterSignup: NewsletterSignupProps;
  OptionalLink: OptionalLinkProps;
  Page4XX: Page4XXProps;
  Page500: Page500Props;
  PageMeta: PageMetaProps;
  Pagination: PaginationProps;
  Paragraph: ParagraphProps;
  Pre: PreProps;
  Prose: ProseProps;
  Quotebox: QuoteboxProps;
  RadioButton: RadioButtonProps;
  Recaptcha: RecaptchaProps;
  RecommendedArticles: RecommendedArticlesProps;
  RelatedArticleSlider: RelatedArticleSliderProps;
  RichTextBox: RichTextBoxProps;
  Row: RowProps;
  SaveArticleButton: SaveArticleButtonProps;
  SavedArticles: SavedArticlesProps;
  SchemaMarkup: SchemaMarkupProps;
  ScrollPageControls: ScrollPageControlsProps;
  Search: SearchProps;
  SecondNativeArticle: SecondNativeArticleProps;
  ShowHero: ShowHeroProps;
  ShowsSlider: ShowsSliderProps;
  Skeleton: SkeletonProps;
  SliderSlide: SliderSlideProps;
  SliderSwiper: SliderSwiperProps;
  Slider: SliderProps;
  SoMe: SoMeProps;
  Spinner: SpinnerProps;
  StateProviders: StateProvidersProps;
  Strossle: StrossleProps;
  Tabs: TabsProps;
  Tag: TagProps;
  TagsIndex: TagsIndexProps;
  TextArea: TextAreaProps;
  TextField: TextFieldProps;
  TextMultiline: TextMultilineProps;
  TextSubtitle: TextSubtitleProps;
  TextTitle: TextTitleProps;
  Tiktok: TiktokProps;
  Timer: TimerProps;
  Tracking: TrackingProps;
  TrendingMenu: TrendingMenuProps;
  Twitter: TwitterProps;
  VideoBaseContent: VideoBaseContentProps;
  VideoReels: VideoReelsProps;
  VideoSlider: VideoSliderProps;
  VideoTranscript: VideoTranscriptProps;
  Youplay: YouplayProps;
  Youtube: YoutubeProps;
  ExpertSlider: ExpertSliderProps;
}

export const Components = {
  Accordion,
  AdTemplate,
  Adunit,
  AffiliateGallery,
  AffiliateItem,
  Article,
  ArticleDate,
  ArticleDisclosureLabel,
  ArticleFooter,
  ArticleHeader,
  ArticleSlider,
  ArticleTeaserBodytext,
  ArticleTeaserDefault,
  AuthorDetails,
  AuthorsList,
  Banner,
  Base,
  Blip,
  BlogSlider,
  Box,
  Breadcrumbs,
  Button,
  Byline,
  Checkbox,
  Checkout,
  CheckoutBody,
  CheckoutFooter,
  CheckoutHeader,
  Columns,
  Confirmation,
  Consent,
  ContactEditorial,
  ContentLock,
  CourseCountdown,
  CoverBox,
  Dialog,
  Direkt,
  Edge,
  Emphasis,
  Facebook,
  FactBox,
  Footer,
  Front,
  FrontCoverstory,
  Hamburger,
  Heading,
  HighlightedArticles,
  Icon,
  Image,
  InputCaption,
  Instagram,
  InstagramArticlesList,
  JwVideoArticle,
  JwVideoShoppable,
  JwVideoTeaser,
  JwVideoVideo,
  KeesingGames,
  KlarnaPayment,
  LineBreak,
  Link,
  List,
  ListGrid,
  Loader,
  LoopAround,
  Main,
  Markup,
  MoreEpisodes,
  MorePagesTitle,
  MostRead,
  Navigation,
  NewsletterSignup,
  OptionalLink,
  Page4XX,
  Page500,
  PageMeta,
  Pagination,
  Paragraph,
  Pre,
  Prose,
  Quotebox,
  RadioButton,
  Recaptcha,
  RecommendedArticles,
  RelatedArticleSlider,
  RichTextBox,
  Row,
  SaveArticleButton,
  SavedArticles,
  SchemaMarkup,
  ScrollPageControls,
  Search,
  SecondNativeArticle,
  ShowHero,
  ShowsSlider,
  Skeleton,
  SliderSlide,
  SliderSwiper,
  Slider,
  SoMe,
  Spinner,
  StateProviders,
  Strossle,
  Tabs,
  Tag,
  TagsIndex,
  TextArea,
  TextField,
  TextMultiline,
  TextSubtitle,
  TextTitle,
  Tiktok,
  Timer,
  Tracking,
  TrendingMenu,
  Twitter,
  VideoBaseContent,
  VideoReels,
  VideoSlider,
  VideoTranscript,
  Youplay,
  Youtube,
  ExpertSlider,
}
