import { tw } from '@/utils/tw';
import BaseTextTitleTheme from 'base/components/TextTitle/theme';

const TextTitle = tw.theme({
  extend: BaseTextTitleTheme,
  base: [
    'lg:data-[page-type=front]:mx-auto',
    'lg:data-[page-type=front]:gap-2',
    'lg:data-[page-type=front]:lg:max-w-col-lg-8',
    'lg:data-[page-type=front]:lg:w-full',
  ],
});

export default TextTitle;
