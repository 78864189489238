/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { SvenskdamStandaloneInstagramArticlesList as ResolvedStandaloneInstagramArticlesList } from 'sites/svenskdam/components/standalone/InstagramArticlesList';

export const InstagramArticlesList: typeof ResolvedStandaloneInstagramArticlesList = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneInstagramArticlesList {...props} />
        </ErrorBoundary>
    );
});

export type InstagramArticlesListProps = PropsFromComponent<typeof InstagramArticlesList>;

/** @deprecated Use slot architecture instead */
export const InstagramArticlesListWith = (extras: DynamicStandaloneExtras): typeof InstagramArticlesList => {
    return function InstagramArticlesList(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'InstagramArticlesList');
        return <ResolvedStandaloneInstagramArticlesList {...mergeProps({ options: { theme } }, props)} />;
    }
}