import { tw } from '@/utils/tw';
import BaseHighlightedArticlesTheme from 'base/components/HighlightedArticles/theme';

const Blip = tw.theme({
  extend: BaseHighlightedArticlesTheme.Blip,
});

const Icon = tw.theme({
  extend: BaseHighlightedArticlesTheme.Icon,
});

const Skeleton = tw.theme({
  extend: BaseHighlightedArticlesTheme.Skeleton,
  base: `h-[91px] md:h-[146px]`,
});

const HighlightedArticles = tw.theme({
  extend: BaseHighlightedArticlesTheme,
  variants: {
    variant: {
      secondary: {
        base: `h-[91px] md:h-[146px]`,
        headline: `text-headline-2xs mt-1 md:mt-3 md:text-headline-2xl`,
        label: `text-headline-3xs md:text-headline-2xs`,
      },
    },
  },
  defaultVariants: {
    variant: 'secondary',
  },
});

export default Object.assign(HighlightedArticles, { Icon, Blip, Skeleton });
