import { tw } from '@/utils/tw';
import BaseTextSubtitleTheme from 'base/components/TextSubtitle/theme';

const TextSubtitle = tw.theme({
  extend: BaseTextSubtitleTheme,
  base: [
    'lg:data-[page-type=front]:mx-auto',
    'lg:data-[page-type=front]:gap-2',
    'lg:data-[page-type=front]:lg:max-w-col-lg-8',
    'lg:data-[page-type=front]:lg:w-full',
  ],
});

export default TextSubtitle;
