import { mergeProps } from '@/utils/mergeProps';
import { StandaloneRecommendedArticles } from 'base/components/RecommendedArticles';

export const SvenskdamStandaloneRecommendedArticles: typeof StandaloneRecommendedArticles = (props) => {
  return (
    <StandaloneRecommendedArticles
      {...mergeProps(
        {
          options: {
            $list: {
              $item: {
                $teaser: {
                  variant: 'horizontal',
                },
              },
            },
          },
        },
        props,
      )}
    />
  );
};
