import { StandaloneShowsSlider } from 'base/components/ShowsSlider';

export const SvenskdamStandaloneShowsSlider: typeof StandaloneShowsSlider = (props) => (
  <StandaloneShowsSlider
    shows={props.shows}
    options={{
      $swiper: {
        spaceBetween: 12,
      },
    }}
  />
);
